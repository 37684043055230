import React from 'react';
import '../styles/globals.css';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import { Footer, Grid, HeroSection, Navigation, SectionHeading } from '../components';
import { GridItem, Slide } from '../types';

const navigateToSolution = (solution: string): void => {
  navigate(`/solutions#${solution}`);
};

const gridItems: GridItem[] = [
  {
    type: 'card',
    button: true,
    action: () => navigateToSolution('smart-urban-mobility'),
    slides: [
      {
        title: 'Smart urban mobility',
        content: `We offer a variety of mobility business solutions, including Account-based ticketing, QR mobile systems, Automatic Vehicle Location, EMV MTT Fare rule engines and software integration. Our Open API Gateway systems enable 3rd party merchant ecosystems and data companies, by structuring streams of data that can be used for GTFS integration or product retailing on external digital platforms. Moreover, we are focusing on Schedule optimization for city-wide transit networks using multi-objective algorithms and real-time data stemming, all with the goal of improving journey and connection passenger experience.`,
      },
    ],
  },
  { type: 'image', image: 'home-grid-1.jpg', copyright: true },
  { type: 'image', image: 'home-grid-2.jpg', copyright: true },
  {
    type: 'card',
    button: true,
    action: () => navigateToSolution('digital-waste-management'),
    slides: [
      {
        title: 'Digital waste management',
        content: `We are proud of being one the pioneers to explore digitalization of waste collection and waste management operations in CEE. Our monitoring and optimization solutions for cities and collection companies are developed based on our experience with various truck and bin sensor devices, different IoT connectivity layers, data structuring and pipelines, optimization algorithms and custom dashboards that deliver user functionalities. Last but not least, we have collected a "truck load" of domain expertise that we are ready to share and use, in order to build solutions in any business context.`,
      },
    ],
  },
];

const testimonials: Slide[] = [
  {
    title: 'Maria Rangelova',
    content: "You can't buy happiness but you can buy cake and that's kind of the same thing",
  },
  {
    title: 'Gergin Darakov',
    content: "If you don't like your job, you don't go on strike. You just go in every day and do it really half-assed",
  },
];

const IndexPage = () => {
  return (
    <main>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>Theoremus</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
        <link rel='canonical' href='' />
      </Helmet>
      <Navigation />
      <HeroSection
        backgroundImage='home.jpg'
        copyright={true}
        heading='Data-driven urban solutions'
        learnMore={true}
        subHeading={`Solving today's urban development demands and identifying future challenges using AI and technology`}
      />
      <SectionHeading
        backgroundColor='black'
        heading='About us'
        subHeading='Open source technology, open data, open minds'
        content={`At Theoremus, we build high-impact, innovative business solutions for urban mobility and smart waste management that have AI and Machine Learning core capabilities embedded in their design.\nOn top of the business solutions we provide, we offer data management and structuring services that organize and maximize the value of the huge streams of data, generated by urban mobility business systems. This allows cities and authorities to engage with ecosystems of data analytics and merchant companies that provide value-added services using these data endpoints.\nLast but not least, we specialize in advanced data analytics for urban mobility and smart waste management. Our data science team strives to discover and analyze future problems, build prototypes and scale the solutions that cities and businesses need, in order to provide sustainable services.`}
      />
      <div className='full-width-image' style={{ backgroundImage: `url('/images/about-us.jpg')` }}></div>
      <SectionHeading
        backgroundColor='white'
        heading='Solution'
        subHeading={`Resolving today's urban challenges with the help of technology`}
      />
      <Grid backgroundColor='white' gridItems={gridItems} />
      {/* <section className='section' style={{ backgroundColor: 'black' }}>
        <SectionHeading
          backgroundColor='black'
          heading='Featured testimonials'
          subHeading='Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        />
        <Slider slides={testimonials} />
      </section> */}
      <Footer />
    </main>
  );
};

export default IndexPage;
